/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import MENU_ROLES from '@/constants/ConstantMenuRoles';
import { useStoreAuth } from '@/stores/StoreAuth';
import { createRouter, createWebHistory } from 'vue-router';

const isEmbedded = process.env.VUE_APP_EMBEDED === 'true';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: !isEmbedded ? '/login' : '/vobys-login',
      component: () => (!isEmbedded ? import('@/layouts/LayoutAuth.vue') : import('@/pages/vobys/PageVobysSso.vue')),
      children: [
        {
          path: '',
          name: 'RouteLogin',
          component: () =>
            !isEmbedded ? import('@/pages/auth/PageLogin.vue') : import('@/pages/vobys/PageVobysSso.vue'),
        },
        {
          path: 'token/tfa/confirm/:token?',
          name: 'RouteConfirmTokenTfa',
          props: true,
          component: () => import('@/pages/auth/PageTfaToken.vue'),
        },
        {
          path: 'forgot/email',
          name: 'RouteForgotEmail',
          component: () => import('@/pages/auth/PageForgotPasswordEmail.vue'),
        },
        {
          path: 'forgot/email/confirm/:token',
          name: 'RouteForgotEmailConfirm',
          props: true,
          component: () => import('@/pages/auth/PageForgotPasswordConfirm.vue'),
        },
        {
          path: 'registration/first/access/:id',
          name: 'RouteTfaFirstAccess',
          props: true,
          component: () => import('@/pages/auth/PageTfaFirstAccess.vue'),
        },
        {
          path: 'forbidden',
          name: 'RouteForbidden',
          component: () => import('@/pages/vobys/PageVobysForbidden.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/LayoutMain.vue'),
      children: [
        {
          path: '',
          name: 'RouteHome',
          component: () => import('@/pages/PageHome.vue'),
        },
        {
          path: 'saude-e-seguranca',
          name: 'RouteHealthCare',
          meta: {
            roles: MENU_ROLES.saudeESeguranca,
          },
          children: [
            {
              path: 'agentes-nocivos-e-aposentadoria-especial',
              name: 'RouteHealthCareAgentesNocivosAposentadoriaEspecial',
              meta: {
                roles: MENU_ROLES.saudeESeguranca,
              },
              component: () => import('@/pages/wss/PageHealthCare.vue'),
            },
            {
              path: 'cat',
              name: 'RouteCAT',
              meta: {
                roles: MENU_ROLES.esocial,
              },
              component: () => import('@/pages/wss/PageCAT.vue'),
            },
          ],
        },
        {
          path: 'configuracoes',
          children: [
            {
              path: 'e-cac',
              children: [
                {
                  path: 'integracao',
                  name: 'RouteSettingsEcacIntegration',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/fonte-dados/PageFonteDadosEcacProcess.vue'),
                },
                {
                  path: 'mensagens',
                  name: 'RouteSettingsEcacMessages',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/PageManagementEcac.vue'),
                },
              ],
            },
            {
              path: 'usuarios',
              name: 'RouteSettingsUsers',
              meta: {
                roles: MENU_ROLES.admin,
              },
              component: () => import('@/pages/management/PageManagementUsuarios.vue'),
            },
            {
              path: 'usuarios/:id',
              name: 'RouteSettingsUser',
              props: true,
              redirect: { name: 'RouteSettingsUserData' },
              component: () => import('@/pages/management/PageManagementUsuario.vue'),
              children: [
                {
                  path: 'dados',
                  name: 'RouteSettingsUserData',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioDados.vue'),
                },
                {
                  path: 'permissoes',
                  name: 'RouteSettingsUserPermissions',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioPermissoes.vue'),
                },
                {
                  path: 'redefinir-senha',
                  name: 'RouteSettingsUserSecurity',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioSeguranca.vue'),
                },
                {
                  path: 'autenticacao-2fa',
                  name: 'RouteSettingsUserAuthentication',
                  props: true,
                  meta: {
                    middleware: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioAuthentication.vue'),
                },
                {
                  path: 'editor-de-menu',
                  name: 'RouteSettingsUserEditorMenu',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioEditorTelas.vue'),
                },
                {
                  path: 'estabelecimentos',
                  name: 'RouteSettingsUserEstablishments',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/usuario/PageManagementUsuarioEstabelecimentos.vue'),
                },
              ],
            },
            {
              path: 'empresas',
              children: [
                {
                  path: '',
                  name: 'RouteSettingsCompanies',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/PageEmpresas.vue'),
                },
                {
                  path: 'estabelecimentos',
                  name: 'RouteSettingsEstablishments',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/PageManagementEstabelecimentos.vue'),
                },
                {
                  path: 'grupos',
                  name: 'RouteSettingsGroups',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/PageManagementGrupos.vue'),
                },
              ],
            },
            {
              path: 'e-social',
              children: [
                {
                  path: 'importacao',
                  name: 'RouteSettingsImport',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/fonte-dados/PageFonteDadosUploadArquivoZip.vue'),
                },
                {
                  path: 'tabelas',
                  children: [
                    {
                      path: '',
                      name: 'RouteSettingsESocialTables',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialTables.vue'),
                    },
                    {
                      path: 'natureza-das-rubricas',
                      name: 'RouteSettingsESocialTablesRubricNature',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialRubricNature.vue'),
                    },
                    {
                      path: 'tipos-de-rubrica',
                      name: 'RouteSettingsESocialTablesRubricType',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialRubricType.vue'),
                    },
                    {
                      path: 'incidencias-para-a-cp',
                      name: 'RouteSettingsESocialTablesIncidenceINSS',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialIncidenceINSS.vue'),
                    },
                    {
                      path: 'incidencias-para-o-irrf',
                      name: 'RouteSettingsESocialTablesIncidenceIRRF',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialIncidenceIRRF.vue'),
                    },
                    {
                      path: 'incidencias-para-o-fgts',
                      name: 'RouteSettingsESocialTablesIncidenceFGTS',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialIncidenceFGTS.vue'),
                    },
                    {
                      path: 'incidencias-para-a-cprp',
                      name: 'RouteSettingsESocialTablesIncidenceCPRP',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialIncidenceCPRP.vue'),
                    },
                  ],
                },
                {
                  path: 'parametros-de-analise',
                  children: [
                    {
                      path: '',
                      name: 'RouteSettingsESocialAnalysisParameters',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () =>
                        import('@/pages/management/e-social/PageManagementESocialAnalysisParameters.vue'),
                    },
                    {
                      path: 'incidencias-por-natureza',
                      name: 'RouteSettingsESocialAnalysisParametersIncidenceNature',
                      meta: {
                        roles: MENU_ROLES.admin,
                      },
                      component: () => import('@/pages/management/e-social/PageManagementESocialIncidenceNature.vue'),
                    },
                  ],
                },
              ],
            },
            {
              path: 'auditoria',
              name: 'RouteSettingsAudit',
              meta: {
                roles: MENU_ROLES.admin,
              },
              component: () => import('@/pages/management/PageManagementAuditoria.vue'),
            },
            {
              path: 'cadastro-de-documentos',
              name: 'RouteSettingsRegistrationDocuments',
              meta: {
                roles: MENU_ROLES.admin,
              },
              component: () => import('@/pages/documents/files/PageFileUpload.vue'),
            },
          ],
        },
        {
          path: 'e-social',
          children: [
            {
              path: 'indicadores',
              name: 'RouteESocialIndicators',
              meta: {
                roles: MENU_ROLES.esocial,
              },
              component: () => import('@/pages/indicadores/PageIndicadores.vue'),
            },
            {
              path: 'empregadores',
              name: 'RouteESocialEmployers',
              meta: {
                roles: MENU_ROLES.esocial,
              },
              component: () => import('@/pages/e-social/PageESocialEmployers.vue'),
            },
            {
              path: 'rubricas',
              name: 'RouteESocialRubricas',
              meta: {
                roles: MENU_ROLES.esocial,
              },
              component: () => import('@/pages/e-social/PageESocialRubricas.vue'),
            },
            {
              path: 'rubricas/:id',
              name: 'RouteESocialRubric',
              props: true,
              meta: {
                roles: MENU_ROLES.esocial,
              },
              component: () => import('@/pages/e-social/PageESocialRubrica.vue'),
            },
          ],
        },

        {
          path: 'eGov',
          children: [
            {
              path: 'cessao',
              name: 'RouteTrabalhadorCessao',
              meta: {
                roles: MENU_ROLES.cessao,
              },
              component: () => import('@/pages/eGOV/cessao/PageTrabalhadorCessao.vue'),
            },
          ],
        },
        {
          path: 'folha-de-pagamento',
          children: [
            {
              path: 'rubricas',
              name: 'RouteESocialRubrics',
              meta: {
                roles: MENU_ROLES.folhaDePagamento,
              },
              component: () => import('@/pages/folha-de-pagamento/PageFolhaPagamentoRubricas.vue'),
            },
          ],
        },
        {
          path: 'trabalhador',
          children: [
            {
              path: 'visao-geral',
              name: 'RouteTrabalhador',
              meta: {
                roles: MENU_ROLES.trabalhador,
              },
              component: () => import('@/pages/trabalhador/visao-geral/PageTrabalhadorVisaoGeral.vue'),
            },
            {
              path: 'afastamento-e-inatividade',
              name: 'RouteTrabalhadorAfastamentoInatividade',
              meta: {
                roles: MENU_ROLES.afastadosInativos,
              },
              component: () =>
                import('@/pages/trabalhador/afastamento-e-inatividade/PageTrabalhadorAfastamentoEInatividade.vue'),
            },
            {
              path: 'cbo',
              name: 'RouteTrabalhadorCBO',
              meta: {
                roles: MENU_ROLES.cbo,
              },
              component: () => import('@/pages/trabalhador/cbo/PageTrabalhadorCBO.vue'),
            },
            {
              path: 'horarios-jornadas',
              name: 'RouteTrabalhadorHorariosJornadas',
              meta: {
                roles: MENU_ROLES.horariosEJornadas,
              },
              component: () => import('@/pages/trabalhador/horarios-jornadas/PageTrabalhadorHorariosJornadas.vue'),
            },
            {
              path: 'historico-horarios-e-jornadas',
              name: 'RouteTrabalhadorHistoricoHorariosJornadas',
              meta: {
                roles: MENU_ROLES.horariosEJornadas,
              },
              component: () =>
                import('@/pages/trabalhador/horarios-jornadas/PageTrabalhadorHistoricoHorariosJornadas.vue'),
            },
            {
              path: 'parametros-de-analise',
              children: [
                {
                  path: 'cbo',
                  name: 'RouteTrabalhadorParametrosAnaliseCBO',
                  meta: {
                    roles: MENU_ROLES.admin,
                  },
                  component: () => import('@/pages/management/trabalhador/PageManagementTrabalhadorCbo.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'e-cac',
          children: [
            {
              path: 'dctf',
              name: 'RouteEcacDctf',
              meta: {
                roles: MENU_ROLES.ecac,
              },
              component: () => import('@/pages/ecac/PageDCTF.vue'),
            },
            {
              path: 'debitos-e-creditos-tributarios',
              name: 'RouteTotals',
              meta: {
                roles: MENU_ROLES.totalizadores,
              },
              component: () => import('@/pages/ecac/PageDCTF.vue'),
            },
            {
              path: 'mensagens',
              name: 'RouteEcacMessages',
              props: true,
              meta: {
                roles: MENU_ROLES.ecacMensagens,
              },
              component: () => import('@/pages/ecac/PageMensagens.vue'),
            },
          ],
        },
        {
          path: 'ativacao',
          name: 'RouteRegistrationUserActivation',
          props: true,
          meta: {
            roles: MENU_ROLES.admin,
          },
          component: () => import('@/pages/management/usuario/PageManagementUsuarioCadastradoAtivacao.vue'),
        },
        {
          path: 'central-de-ajuda',
          name: 'RouteHelpCenter',
          component: () => import('@/pages/help-desk/PageHelpDeskChamados.vue'),
        },
        {
          path: 'minha-conta',
          name: 'RouteLayoutProfile',
          redirect: { name: 'RouteLayoutProfileEdit' },
          component: () => import('@/layouts/LayoutUserProfile.vue'),
          children: [
            {
              path: 'perfil',
              name: 'RouteLayoutProfileEdit',
              component: () => import('@/pages/auth/PagePerfil.vue'),
            },
            {
              path: 'editar-senha',
              name: 'RouteLayoutProfilePasswordChange',
              component: () => import('@/pages/auth/PageChangePassword.vue'),
            },
            {
              path: 'autenticacao-2fa',
              name: 'RouteLayoutProfileAuthentication',
              component: () => import('@/pages/auth/PageAuthentication2FA.vue'),
            },
          ],
        },
        {
          path: 'certidoes',
          children: [
            {
              path: 'cnd-cpen',
              name: 'RouteCertificatesCnd',
              meta: {
                roles: MENU_ROLES.certidoes,
              },
              component: () => import('@/pages/certidao/PageCertidoes.vue'),
            },
            {
              path: 'historico/:idCertidao',
              name: 'RouteCertificatesHistoric',
              props: true,
              meta: {
                roles: MENU_ROLES.certidoes,
              },
              component: () => import('@/pages/certidao/PageCertidaoHistorico.vue'),
            },
            {
              path: 'crf',
              children: [
                {
                  path: '',
                  name: 'RouteCrf',
                  meta: {
                    roles: MENU_ROLES.crf,
                  },
                  component: () => import('@/pages/certidao/PageCertidaoCrf.vue'),
                },
                {
                  path: 'historico/:idCrf',
                  name: 'RouteCrfHistoric',
                  props: true,
                  meta: {
                    roles: MENU_ROLES.crf,
                  },
                  component: () => import('@/pages/certidao/PageCertidaoCrfHistorico.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'anexos',
          name: 'RouteDocumentsFile',
          component: () => import('@/pages/documents/files/PageFile.vue'),
        },
        {
          path: 'exportacoes',
          name: 'RouteDocumentsDownload',
          component: () => import('@/pages/documents/downloads/PageDownload.vue'),
        },
      ],
    },
    {
      path: '/playground',
      component: () => import('@/layouts/LayoutPlayground.vue'),
      children: [
        {
          path: '',
          name: 'StableHome',
          component: () => import('@/pages/playground/PagePlaygroundHome.vue'),
        },
        {
          path: 'button',
          name: 'PlaygroundButton',
          meta: { title: 'Buttons', subtitle: 'AGButton' },
          component: () => import('@/pages/playground/PagePlaygroundButtons.vue'),
        },
        {
          path: 'button-icon',
          name: 'PlaygroundButtonIcon',
          meta: { title: 'Icon Buttons', subtitle: 'AGButtonIcon' },
          component: () => import('@/pages/playground/PagePlaygroundButtonsIcon.vue'),
        },
        {
          path: 'table-server',
          name: 'PlaygroundTableServer',
          meta: { title: 'Table Server', subtitle: 'AGDataTableServer' },
          component: () => import('@/pages/playground/PagePlaygroundTableServer.vue'),
        },
        {
          path: 'table',
          name: 'PlaygroundTable',
          meta: { title: 'Table', subtitle: 'AGDataTable' },
          component: () => import('@/pages/playground/PagePlaygroundTable.vue'),
        },
        {
          path: 'tabs',
          name: 'PlaygroundTabs',
          meta: { title: 'Tabs', subtitle: 'AGTabs' },
          component: () => import('@/pages/playground/PagePlaygroundTabs.vue'),
        },
        {
          path: 'tags',
          name: 'PlaygroundTags',
          meta: { title: 'Tags', subtitle: 'AGTag' },
          component: () => import('@/pages/playground/PagePlaygroundTags.vue'),
        },
        {
          path: 'switch',
          name: 'PlaygroundSwitch',
          meta: { title: 'Switches', subtitle: 'AGSwitch' },
          component: () => import('@/pages/playground/PagePlaygroundSwitch.vue'),
        },
        {
          path: 'menu',
          name: 'PlaygroundMenu',
          meta: { title: 'Menus', subtitle: 'AGMenu' },
          component: () => import('@/pages/playground/PagePlaygroundMenu.vue'),
        },
        {
          path: 'text-field',
          name: 'PlaygroundTextField',
          meta: { title: 'Text fields', subtitle: 'AGTextField' },
          component: () => import('@/pages/playground/PagePlaygroundTextField.vue'),
        },
        {
          path: 'currency-input',
          name: 'PlaygroundCurrencyInput',
          meta: { title: 'Currency Inputs', subtitle: 'AGCurrencyInput' },
          component: () => import('@/pages/playground/PagePlaygroundCurrencyInput.vue'),
        },
        {
          path: 'autocomplete',
          name: 'PlaygroundAutocomplete',
          meta: { title: 'Autocompletes', subtitle: 'AGAutocomplete' },
          component: () => import('@/pages/playground/PagePlaygroundAutocomplete.vue'),
        },
        {
          path: 'filter',
          name: 'PlaygroundFilter',
          meta: { title: 'Filters', subtitle: 'AGFilter' },
          component: () => import('@/pages/playground/PagePlaygroundFilter.vue'),
        },
        {
          path: 'icon',
          name: 'PlaygroundIcon',
          meta: { title: 'Icons', subtitle: 'AGIcon' },
          component: () => import('@/pages/playground/PagePlaygroundIcon.vue'),
        },
        {
          path: 'dialog',
          name: 'PlaygroundDialog',
          meta: { title: 'Dialogs', subtitle: 'AGDialog' },
          component: () => import('@/pages/playground/PagePlaygroundDialog.vue'),
        },
        {
          path: 'notification',
          name: 'PlaygroundNotification',
          meta: { title: 'Notifications', subtitle: 'AGNotification' },
          component: () => import('@/pages/playground/PagePlaygroundNotification.vue'),
        },
        {
          path: 'alert',
          name: 'PlaygroundAlert',
          meta: { title: 'Alerts', subtitle: 'AGAlert' },
          component: () => import('@/pages/playground/PagePlaygroundAlert.vue'),
        },
        {
          path: 'password',
          name: 'PlaygroundPassword',
          meta: { title: 'Password Field', subtitle: 'AGPasswordField' },
          component: () => import('@/pages/playground/PagePlaygroundPasswordField.vue'),
        },
        {
          path: 'input-file',
          name: 'PlaygroundInputFile',
          meta: { title: 'Input File', subtitle: 'AGInputFile' },
          component: () => import('@/pages/playground/PagePlaygroundInputFile.vue'),
        },
        {
          path: 'date-picker',
          name: 'PlaygroundDatePicker',
          meta: { title: 'Date Picker', subtitle: 'AGDatePicker' },
          component: () => import('@/pages/playground/PagePlaygroundDatePicker.vue'),
        },
        {
          path: 'date-picker-month',
          name: 'PlaygroundDatePickerMonth',
          meta: { title: 'Month Picker', subtitle: 'AGDatePickerMonth' },
          component: () => import('@/pages/playground/PagePlaygroundDatePickerMonth.vue'),
        },
        {
          path: 'select',
          name: 'PlaygroundSelect',
          meta: { title: 'Select', subtitle: 'AGSelect' },
          component: () => import('@/pages/playground/PagePlaygroundSelect.vue'),
        },
        {
          path: 'date-picker-range',
          name: 'PlaygroundDatePickerRange',
          meta: { title: 'Date Picker Range', subtitle: 'AGDatePickerRange' },
          component: () => import('@/pages/playground/PagePlaygroundDatePickerRange.vue'),
        },
        {
          path: 'month-picker-range',
          name: 'PlaygroundDatePickerRangeMonth',
          meta: { title: 'Month Picker Range', subtitle: 'AGDatePickerRangeMonth' },
          component: () => import('@/pages/playground/PagePlaygroundDatePickerRangeMonth.vue'),
        },
        {
          path: 'card',
          name: 'PlaygroundCard',
          meta: { title: 'Card', subtitle: 'AGCard' },
          component: () => import('@/pages/playground/PagePlaygroundCard.vue'),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta?.roles) return next();

  const storeAuth = useStoreAuth();

  if (!storeAuth.user?.id) {
    await storeAuth.getUser();
  }

  const hasPermission = to.meta.roles.some((role) => storeAuth.authorities?.includes(role));
  storeAuth.setMessage('');

  if (!storeAuth.token) {
    return next({ name: 'RouteLogin' });
  }

  if (!hasPermission) {
    storeAuth.setMessage(
      'Esta funcionalidade não está disponível no seu plano de serviço. Solicite este modulo para seu consultor da AGNES.',
    );

    return next({ name: 'RouteHome' });
  }

  return next();
});

export default router;

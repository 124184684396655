/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '@/styles/app.scss';

// Composables
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { md } from 'vuetify/iconsets/md';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { pt } from 'vuetify/locale';

const isEmbedded = process.env.VUE_APP_EMBEDED === 'true';

// if (isEmbedded) {
//   import '@/styles/app-embedded.scss';
// } else {
//   import '@/styles/app.scss';
// }

const agnes = {
  primary: '#442D96',
  iconColor: '#442D96',
  secondary: '#c1c1c1',
  success: '#442D96',
  activeOne: '#ffffff',
  textColor: '#ffffff',
  error: '#610006',
  titleColor: '#442d96',
  background: '#ffffff',
  menuBackground: '#442D96',
  menuWssBackground: '#2a1c4f',
  info: '#442D96',
  primaryLight: '#ECE7F5',
  primaryMedium: '#442D96',
  primaryDark: '#2A1C4F',
  lowLight: '#ffffff',
  lowMedium: '#f7f7fd',
  lowDark: '#e7e7fa',
  highlightLight: '#e3FFee',
  highlightMedium: '#00ff99',
  highlightDark: '#00992b',
};

const other = {
  primary: '#9d9fa1',
  iconColor: '#9d9fa1',
  secondary: '#c1c1c1',
  success: '#8897AA',
  activeOne: '#f8f8f8',
  textColor: '#9d9fa1',
  error: '#8c2020',
  titleColor: '#4e5155',
  info: '#2f3337',
  background: '#f8f8f8',
  menuWssBackground: '#2f3337',
  menuBackground: '#2f3337',
  primaryLight: '#ECE7F5',
  primaryMedium: '#2f3337',
  primaryDark: '#2f3337',
  lowLight: '#ffffff',
  lowMedium: '#f8f8f8',
  lowDark: '#f8f8f8',
  highlightLight: '#e3FFee',
  highlightMedium: '#00ff99',
  highlightDark: '#00992b',
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'projectTheme',
    themes: {
      projectTheme: {
        dark: false,
        colors: isEmbedded ? other : agnes,
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      md,
    },
  },
  defaults: {
    global: {
      ripple: false,
    },
  },
  locale: {
    locale: 'pt',
    messages: { pt },
    fallback: 'pt',
  },
});

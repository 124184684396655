/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import i18n from '@/plugins/vue-i18n';
import router from '@/router';
import pinia from '@/stores';
import { plugin as InputFacade } from 'vue-input-facade';
import vuetify from './vuetify';

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(InputFacade, {
      name: 'mask',
      tokens: {
        '#': { pattern: /\d/ },
        A: { pattern: /[a-z]/i },
        N: { pattern: /[0-9a-z]/i },
        X: { pattern: /./ },
      },
    });
}

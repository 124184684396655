import { ptBR } from '@/i18n/pt-BR';
import { createI18n } from 'vue-i18n';

const messages = {
  'pt-BR': ptBR,
};

const i18n = createI18n({
  locale: 'pt-BR',
  warnHtmlMessage: false,
  messages,
});

export default i18n;

import router from '@/router';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token && !config.confirmingPassword) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');

      router
        .push({ name: 'RouteLogin' })
        .then(() => {})
        .catch(() => {});
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

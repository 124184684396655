const admin = ['ROLE_ADMIN'];
const ecac = ['ROLE_MENU_ECAC', 'ROLE_ADMIN'];
const saudeESeguranca = ['ROLE_MENU_SAUDE_SEGURANCA', 'ROLE_ADMIN'];
const ecacMensagens = ['ROLE_MENU_ECAC_MENSAGENS', 'ROLE_ADMIN'];
const esocial = ['ROLE_MENU_ESOCIAL', 'ROLE_ADMIN'];
const index = ['ROLE_MENU_INTELLIGENCE', 'ROLE_ADMIN'];
const certidoes = ['ROLE_MENU_CERTIDOES', 'ROLE_ADMIN'];
const crf = ['ROLE_MENU_CRF', 'ROLE_ADMIN'];
const horariosEJornadas = ['ROLE_MENU_HORARIOS_JORNADA', 'ROLE_ADMIN'];
const totalizadores = ['ROLE_MENU_TOTALIZADORES', 'ROLE_ADMIN'];
const afastadosInativos = ['ROLE_MENU_AFASTADOS_INATIVOS', 'ROLE_ADMIN'];
const cessao = ['ROLE_MENU_CESSAO', 'ROLE_ADMIN'];
const cbo = ['ROLE_MENU_CBO', 'ROLE_ADMIN'];
const trabalhador = ['ROLE_MENU_TRABALHADORES', 'ROLE_ADMIN'];
const egov = ['ROLE_MENU_EGOV', 'ROLE_ADMIN'];
const folhaDePagamento = ['ROLE_MENU_FOLHA_PAGAMENTO', 'ROLE_ADMIN'];

const MENU_ROLES = {
  admin,
  ecac,
  ecacMensagens,
  esocial,
  index,
  certidoes,
  crf,
  totalizadores,
  afastadosInativos,
  cessao,
  cbo,
  horariosEJornadas,
  saudeESeguranca,
  trabalhador,
  folhaDePagamento,
  egov,
};

export default MENU_ROLES;

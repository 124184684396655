const STATE_TYPE = {
  PENDENTE_DE_VALIDACAO: 'Pendente de validação',
  CONCILIADA: 'Conciliada',
  PENDENTE_APROVACAO: 'Pendente aprovação',
  PENDENTE_PARAMETRIZACAO: 'Pendente de parametrização',
  DIVERGENTE: 'Divergente',
  REPROVADA: 'Sugestão não aprovada',
  READY: 'Concluído',
  PROCESSING: 'Aguardando',
  FAILED: 'Cancelado',
};

export default STATE_TYPE;

import STATE_TYPE from '@/constants/ConstantStateType';
import currency from 'currency.js';

export default class UtilsMask {
  static cnpj(value) {
    if (!value) return '';

    value = value.toString();

    if (value.length === 13) {
      value = '0'.concat(value);
    }

    if (value.length === 8) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?/, '$1.$2.$3');
    if (value.length === 11) return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
    if (value.length === 12) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4');
    if (value.length === 14)
      return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

    return value;
  }

  static cpf(value) {
    if (!value) return '';

    value = value.toString();

    if (value.length === 11) return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');

    return value;
  }

  static cnae(value) {
    if (!value) return '';

    value = value.toString();

    if (value.length === 3) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{1})?/, '$1.$2');
    if (value.length === 5) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{2})?(\d{1})?/, '$1.$2-$3');
    if (value.length === 7) return value.replace(/\D/g, '').replace(/^(\d{4})(\d{1})?(\d{2})?/, '$1-$2/$3');

    return value;
  }

  static cep(value) {
    if (!value) return '';

    if (value.length === 8) return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, '$1-$2');

    return value;
  }

  static money(value, precision = 2, symbol = 'R$ ', defaultValue = 'R$ 0,00') {
    if (!value) return defaultValue;

    try {
      return currency(value, { symbol, separator: '.', decimal: ',', precision }).format();
    } catch (e) {
      return value;
    }
  }

  static empty(value) {
    if (!value) return '---';

    return value;
  }

  static statusType(value) {
    if (!value) return value;

    return STATE_TYPE[value] ?? value;
  }
}

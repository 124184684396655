import axiosInstance from '@/plugins/axios';

export default class ServiceFinancial {
  static endpoint = 'financial';

  static financial = {
    getAll: (pagination) => {
      return axiosInstance.get(`${this.endpoint}/creditos?${pagination}`);
    },

    getById: (idTotalizador) => {
      return axiosInstance.get(`${this.endpoint}/creditos/${idTotalizador}`);
    },

    save: (params) => {
      return axiosInstance.post(`${this.endpoint}/creditos`, params);
    },

    update: (totalizador) => {
      return axiosInstance.put(`${this.endpoint}/creditos/${totalizador.id}`, totalizador);
    },

    delete: (idTotalizador) => {
      return axiosInstance.delete(`${this.endpoint}/creditos/${idTotalizador}`);
    },

    hasNewTotalizers: () => {
      return axiosInstance.get(`${this.endpoint}/creditos/hasnewtotalizers`);
    },
  };
}

import axiosInstance from '@/plugins/axios';

export default class ServiceAuth {
  static endpoint = 'auth';

  static passwordReset = {
    confirmNewPassword: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/reset`, data);
    },

    existsEmailAndSendToken: (email, isRegistration) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/reset?identificator=${email}&isRegistration=${isRegistration}`);
    },

    validateToken: (data) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/reset/token?token=${data.token}`);
    },

    byEmail: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/reset/${data.id}`, {
        email: data.email,
      });
    },

    bySms: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/reset/${data.id}`, {
        phone: data.phone,
      });
    },

    byQuestions: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/reset/${data.id}`, {
        questionOne: data.questionOne,
        questionTwo: data.questionTwo,
        questionThree: data.questionThree,
      });
    },

    wasLast3Passwords: (data) => {
      return axiosInstance.get(
        `${ServiceAuth.endpoint}/reset/valid/password?identificator=${data.identificator}&password=${data.password}`,
      );
    },

    validateTokenCrypt: (data) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/reset/valid/token?accesscode=${data}`);
    },
  };

  static role = {
    get: () => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/role`);
    },
  };

  static recaptchaValidate = (params) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${ServiceAuth.endpoint}/recaptcha/validate`, params)
        .then((response) => {
          if (response.data.hasErrors) {
            reject(response.data.message);
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.hasOwnProperty('hasErrors')) {
            reject(error.response.data.message);
          } else {
            reject(error.message);
          }
        });
    });
  };

  static user = {
    get: (id) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/${id}`);
    },

    find: () => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user?page=0&size=100`);
    },

    login: (user, config = {}) => {
      const passwordEncode = encodeURIComponent(user.password).replace(/\W/g, (c) => {
        return c.charAt(0);
      });
      return axiosInstance.post(
        `${ServiceAuth.endpoint}/oauth/token?username=${user.username}&password=${passwordEncode}&grant_type=password`,
        {},
        {
          ...config,
          headers: {
            Authorization: 'Basic d2ViLWZyb250LWVuZDpiYXItc2VydmljZS1wYXNzd29yZA==',
          },
        },
      );
    },

    save: (data) => {
      return axiosInstance.post(`${ServiceAuth.endpoint}/user`, data);
    },

    update: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/user/me`, data);
    },

    updateOtherUser: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/user/${data.id}`, {
        authorities: data.authorities,
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
      });
    },

    updateOtherUserMenu: (id, data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/user/${id}`, data);
    },

    updatePassword: (data) => {
      return axiosInstance.patch(`${ServiceAuth.endpoint}/user/me/change-password`, data);
    },

    getUser: () => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/me/`);
    },

    existsUsername: (value) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/exists/login?login=${value}`);
    },

    existsEmail: (value) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/exists/email?email=${value}`);
    },

    activates: (value) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/activates?email=${value}`);
    },

    qtyLoginAttempts: (value) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/attemps?username=${value}`);
    },

    enableTwofa: (uuid) => {
      if (uuid) {
        return axiosInstance.put(`${ServiceAuth.endpoint}/user/enable-2fa?uuid=${uuid}`);
      }
      return axiosInstance.put(`${ServiceAuth.endpoint}/user/enable-2fa`);
    },

    disableTwofa: (uuid) => {
      if (uuid) {
        return axiosInstance.put(`${ServiceAuth.endpoint}/user/disable-2fa?uuid=${uuid}`);
      }
      return axiosInstance.put(`${ServiceAuth.endpoint}/user/disable-2fa`);
    },

    loginTwofa: (user) => {
      return axiosInstance.post(`${ServiceAuth.endpoint}/user/login-2fa/${user.accessCode}`, {
        username: user.username,
        password: user.password,
      });
    },

    checkTokenExpirationTime: (identificator) => {
      return axiosInstance.get(`${ServiceAuth.endpoint}/user/token/valid/${identificator}`);
    },
  };
}

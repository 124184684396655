import axiosInstance from '@/plugins/axios';

export default class ServiceCertificate {
  static endpoint = 'certificate';

  static certidoes = {
    get: (pagination) => {
      return axiosInstance.get(`${this.endpoint}/certificate?${pagination}`);
    },
    dashboard: () => {
      return axiosInstance.get(`${this.endpoint}/certificate/dashboard`);
    },
    filter: (pagination, params) => {
      return axiosInstance.post(`${this.endpoint}/certificate/filter?${pagination}`, params);
    },
    post: (params) => {
      return axiosInstance.post(`${this.endpoint}/certificate`, params);
    },
    put: (params) => {
      return axiosInstance.put(`${this.endpoint}/certificate`, params);
    },
    delete: (idCertidao) => {
      return axiosInstance.delete(`${this.endpoint}/certificate/${idCertidao}`);
    },
    getHistorico: (pagination, idCertidao) => {
      return axiosInstance.get(`${this.endpoint}/certificate/historic/${idCertidao}?${pagination}`);
    },
    getPendency: () => {
      return axiosInstance.get(`${this.endpoint}/certificate/haspendentcnds`);
    },
  };
}
